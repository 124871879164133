@font-face {
  font-family: "SF Pro Regular";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

@font-face {
  font-family: "SF Pro Bold";
  font-weight: 800;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
}

@font-face {
  font-family: "SF Pro Medium";
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
}

@font-face {
  font-family: "SF Pro Semibold";
  font-weight: 600;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
}

body{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.accordion {
  --bs-accordion-color: none !important;
  --bs-accordion-active-bg: none !important;
  --bs-accordion-active-color: none !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-btn-focus-border-color: none !important;
  --bs-accordion-btn-active-icon: none !important;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
}

#heroContainer{
  color: white;
  width:100%;
  height: 820px;
  background-image: url('./assets/heroBG.svg');
  background-size: cover;
  background-position: center;
  margin-bottom: 80px;
}
#Whycontainer{
  width:100%;
}

#ContactForm{
  color: white;
  background-image: url('./assets/heroBG.svg');
  background-size: cover;
  background-position: center;
}

#navContainer{
  display:flex;
}

#navbar{
  align-self: center;
}

#navbarButton{
  color:white;
  border-color:white;
  white-space: nowrap;
  text-decoration: none;
  padding-top: 16px;
  padding-left: 32px;
  padding-bottom: 16px;
  padding-right: 32px;
  font-family: 'SF Pro Semibold';
  margin-left: 27px;
}

#hero{
  margin-top: 162px;
  max-width: 1011px;
  width: 100%;
}
#HeroTxtContainer{
  margin-top: 152px;
}
.HeroText{
  line-height: .7;
}


#heroParagraph{
  margin-top: 24px;
}

#heroButton{
  margin-top: 60px;
  background-color: #9678FA;
  border: none;
  width: 227px;
  height: 54px;
  font-size:18px;
  align-items: center;
  font-family: 'SF Pro Semibold';
}
#SmHeroButton{
  margin-top: 60px;
  background-color: #9678FA;
  border: none;
  width: 100%;
  height: 54px;
  font-size:18px;
  align-items: center;
}
#HeroButtonText{
  padding-top: 8px;
}

#RushTextContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

#RushText{
  margin-bottom: 0px;
}
.LinkAnchor{
  text-decoration: none;
}

@media screen and (min-width:769px) and (max-width: 970px) {
  #FormRow{
    flex-direction: column!important;
    margin-left: 25%;
    padding-bottom: 78px;
  }
}
@media screen and (max-width: 970px) {
  #FormRow{
    flex-direction: column!important;
    width: 50%;
    padding-bottom: 78px;
  }
  .FormRowAux{
    width: 1/3;
  }
  #RushText{
    padding-top: 24px;
    padding-bottom: 24px;
  }
}/* 834 hide big sc buttons, reveal small sc buttons */
@media screen and (min-width: 971px) {
  #FormRow{
    flex-direction: row!important;
    offset: 25%;
    width: 50%;
    margin-left: 25%;
    padding-bottom: 120px;
  }
}/* 835 reveal big sc buttons, hide small sc buttons */

@media screen and (min-width: 1396px) {
  .SecondRowAbout{
    display: none;
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (max-width: 1507px) {
  .SecondRowAbout{
    height: 0px;
    overflow: hidden;
  }
}
@media screen and (min-width: 1508px) {
  #RowAux{
    display: none;
  }
  .SecondRowMd{
    height: 0px;
    overflow: hidden;
    padding: 0px;
    margin: 0px!important;
    display: none!important;
  }
}

@media screen and (min-width: 768px) {

  #TestRight{
    margin-right: 16px;
  }
  #TestLeft{
    margin-left: 16px;
  }
  .SecondRowMd{
    margin-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
  }
  #FirstRowAbout {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 120px;
  }
  #aboutUsImage{
    display:'block';
    width:100%;
    height: 348px;
    padding-right: 15px;
  }
  #aboutUsContainer{
    text-align: left;
  }
  .CardDiv{
    padding-top: 40px;
    background-color: #F8F8F8;
    margin-bottom: 60px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 35px;
    
  }
  .CardDivcontainer{
    flex-grow: 1;
    height: auto;
  }
  .WhyRow{
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
  }
  #TestContainer{
    display: flex;
    margin-left: 60px;
    margin-right: 60px;
    padding-top: 32px;
    
  }
  .FAQSection{
    margin-top: 132px;
    padding-left: 5rem;
    padding-right: 5rem;
    margin-bottom: 200px;
  }
  .FormSection1{
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }
  .FormRow{
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
  }
  .TestRow{
    display: flex;
    flex-direction: row;
    margin-bottom: 122px;
  }
  #SendButton{
    color:white;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-color:white;
    white-space: nowrap;
    text-decoration: none;
  }
  #CallBtn{
    background-color: #9678FA;
    border-color: transparent;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  #CallBtn:hover{
    background-color: #734BF9;
  }
  #CallBtn:focus{
    background-color: #B9A5FC;
  }
  #CallBtn:active{
    background-color: #734BF9;
    border: 1px, solid, #142566;
  }
  #CallBtn:disabled{
    color: #C6C6CD;
    background-color: #8C8D9B;
  }
  #SendButton:hover{
    background-color: #EEE9FE33;
  }
  #SendButton:focus{
    background-color: #EEE9FE33;
  }
  #SendButton:active{
    background-color: #EEE9FE33;
    border: 2px, solid, #200C63;
  }
  .Footer1{
    width: 644px;
    padding-right: 160px;
  }
  .Footer1 p {
    margin-bottom: 8px;
  }
  .FooterContainer{
    padding-left: 48px;
    text-align: left;
  }
  #LogoFooter{
    width: 110px;
    height: 44px;
    margin-left: 48px;
    margin-top: 69px;
  }
  .Footer2{
    margin-top: 28;
    position: relative;
  }
  
  .Footer2Container{
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 90px;
  }
  .SmHeroTitleSection{
    text-align: left;
  }
  #FormRow{
    margin-left: 25%;
  }
}
/* @media screen and (min-width: 767px) {
  #RowAux{
    height: 0px;
    overflow: hidden;
    padding: 0px;
    margin: 0px!important;
    display: none!important;
  }
} */


@media screen and (max-width: 767px) {
  #CallBtn:hover{
    background-color: #734BF9;
  }
  #CallBtn:focus{
    background-color: #B9A5FC;
  }
  #CallBtn:active{
    background-color: #734BF9;
    border: 1px, solid, #142566;
  }
  #CallBtn:disabled{
    color: #C6C6CD;
    background-color: #8C8D9B;
  }
  #SendButton:hover{
    background-color: #EEE9FE33;
  }
  #SendButton:focus{
    background-color: #EEE9FE33;
  }
  #SendButton:active{
    background-color: #EEE9FE33;
    border: 2px, solid, #200C63;
  }
  #SendButton{
    color:white;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-color:white;
    white-space: nowrap;
    text-decoration: none;
  }
  .SecondRowMd{
    height: 0px;
    overflow: hidden;
  }
  #RowAux{
    height: 0px;
    overflow: hidden;
    padding: 0px;
    margin: 0px!important;
    display: none!important;
  }

  #FirstRowAbout {
    padding-left: 0px;
    padding-right: 0px;
  }
  #aboutUsImage{
    display:'block';
    width:100%;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 80px;
    margin-bottom: 30px;
  }
  #aboutUsContainer{
    text-align: left;
  }
  #ExcitingSwSolTxt{
    margin-top: 120px;
    margin-bottom: 24px;
  }
  #SmCharImg{
    width: auto;
    margin-top: 50px;
  }
  #Researchtext{
    padding-left: 20px;
    margin-bottom: 24px;
    margin-top: 50px;
  }
  .CardDiv{
    padding-top: 40px;
    background-color: #F8F8F8;
    margin-bottom: 32px;
    height: auto;
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .WhyRow{
    padding-left: 20px;
    padding-right: 20px;
  }
  #TestContainer{
    margin-left: 60px;
    margin-right: 60px;
    padding-top: 32px;
  }
  #TestimonialTitle{
    margin-top: 85px;
  }
  .SmCardTest{
    padding-top: 60px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .CharSlot{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
  }
  .DataSection{
    padding-left: 20px;
    text-align: left;
    line-height: .7;
  }
  .SmCharPortrait{
    padding-top: 8px;
  }
  .FAQSection{
    margin-top: 132px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 200px;
  }
  .accordion-button{
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  .FormSection1{
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .FormRow{
    text-align: left;
  }
  .TellUsSection{
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
  }
  .TestRow{
    display: flex;
    flex-direction: column;
    margin-bottom: 78px;
    padding-left: 40px;
    padding-right: 40px;
  }
  /* #SmSendBtn{
    background-color: transparent;
    color:white;
    border-color:white;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 20px;
  } */
  #CallBtn{
    background-color: #9678FA;
    border-color: transparent;
    margin-top: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    width:100%;
    display:block;
  }
  .FooterContainer{
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }
  #LogoFooter{
    width: 110px;
    height: 44px;
    margin-left: 20px;
    margin-top: 60px;
  }
  .Footer2Container{
    flex-direction: column;
    margin-right: 90px;
  }
  #SmFooterAuxContainer{
    display: flex;
    flex-direction: row;
    padding: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .SmHeroContainer{
    text-align: left;
    width: 113px;
  }
  #SmLogo{
    padding-top: 47px;
    width: 100%;
    height: auto;
  }
  .SmHeroTitleSection{
    text-align: left;
    line-height: .8;
    margin-top: 84px;
  }
}

/* Card Div Rules */
@media screen and (min-width: 1991px) {
  .CardDiv{
    height: 259px;
  }
}
@media screen and (min-width: 1803px)and (max-width: 1990px) {
  .CardDiv{
    height: 279px;
  }
}
@media screen and (min-width: 1472px)and (max-width: 1802px) {
  .CardDiv{
    height: 298px;
  }
}
@media screen and (min-width: 1300px)and (max-width: 1471px) {
  .CardDiv{
    height: 352px;
  }
}
@media screen and (min-width: 1210px)and (max-width: 1299px) {
  .CardDiv{
    height: 372px;
  }
}
@media screen and (min-width: 1046px)and (max-width: 1209px) {
  .CardDiv{
    height: 391px;
  }
}
@media screen and (min-width: 1007px)and (max-width: 1045px) {
  .CardDiv{
    height: 411px;
  }
}
@media screen and (min-width: 1007px)and (max-width: 1045px) {
  .CardDiv{
    height: 411px;
  }
}
@media screen and (min-width: 991px)and (max-width: 1006px) {
  .CardDiv{
    height: 373px;
  }
}
@media screen and (min-width: 898px)and (max-width: 990px) {
  .CardDiv{
    height: 352px;
  }
}
@media screen and (min-width: 839px)and (max-width: 897px) {
  .CardDiv{
    height: 372px;
  }
}
@media screen and (min-width: 767px)and (max-width: 838px) {
  .CardDiv{
    height: 334px;
  }
}
@media screen and (max-width: 766px) {
  .CardDiv{
    height: auto;
  }
}
@media screen and (min-width: 636px)and (max-width: 960px) {
  .navlink{
    margin-left: 8px!important;
    -webkit-font-smoothing: antialiased;
  }
  #navbarButton{
    padding-right: 10px;
    padding-left: 10px;
  }
}

#FamiliarImage{
  display:'block';
  width:100%;
  height: 348px;
}

#aboutUsContent{
  text-align: left;
}



#FamiliarTitle{
  text-align: left;
  line-height: 32px;
  margin-bottom: 24px;
}

.cardTitle{
  padding-bottom: 8px;
  color: #200C63;
}

.cardText{
  font-size: small;
}

#WhyTitle{
  margin-top: 120px;
  margin-bottom: 60px;
}

.familiarText{
  text-align: left;
}

.navlink{
  white-space: nowrap;
  color: white;
  text-decoration: none;
  margin-left: 2rem;
  margin-top: 6px;
  -webkit-font-smoothing: antialiased;
}
.navlink:hover{
    font-weight:600;
}
.TextButton:hover{
  font-weight:600;
}
#AboutUsText{
  width: 70px;
}
#ServicesText{
  width:65px;
}
#WhyPicnicText{
  width:90px;
}
#TestimonialsText{
  width: 100px;
}
#FAQsText{
  width: 44px;
}

#logo{
  height: 38px;
  margin-top: 47px;
  margin-left: 60px;
}

.me-auto{
  display: flex;
  align-items: center;
  -webkit-font-smoothing: antialiased;
}

.BaseCard{
  height: 248px;
}

.WeLove{
  margin-top: 28px;
}

.Display-S{
  font-family: 'Zilla Slab', bold;
  font-size: 32px;
}

.Display-M{
  font-family: 'Zilla Slab', bold;
  font-size: 36px;
}

.Display-L{
  font-family: 'Zilla Slab', bold;
  font-size: 48px;
}

.Display-XL{
  font-family: 'Zilla Slab', bold;
  font-size: 64px;
}

.Display-XXL{
  font-family: 'Zilla Slab', bold;
  font-size: 96px;
}

.Descriptions-SF{
  font-family: 'SF Pro Medium';
  font-style: normal;
  font-size: 14;
}
.Copy-SF{
  font-family: 'SF Pro Medium';
  font-style: normal;
  font-size: 16;
  -webkit-font-smoothing: antialiased;
}

.nav-link{
  font-family: 'SF Pro Semibold';
  font-style: normal;
  font-size: 18;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0 !important;
}

.Subtitle-SF{
  font-family: 'SF Pro Semibold';
  font-style: normal;
  font-size: 20;
}
.CopyBold{
  font-family: 'SF Pro Bold';
  font-style: normal;
  font-size: 16;
}

.Title{
  font-family: 'SF Pro Bold';
  font-weight: bold;
  font-style: normal;
  font-size: 24;
}

.Purple4{
  color: #9678FA;
}

#mail{
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

#phone{
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

#company{
  padding-top: 28px;
}

#greet{
  padding-top: 28px;
}

#final{
  padding-bottom: 48px;
  margin-bottom: 48px;
}

#LetsTalk{
  color: white;
  padding-top: 124px;
  line-height: normal;
}

.Textarea{
  height: 120px;
  margin-bottom: 24px;
}

.white{
  background-color: white;
}
.Text-Left{
  text-align: left;
}
.PaddingSm{
  padding-left: 20px;
  padding-right: 20px;
}
#RowAux{
  padding-left: 60px;
  /* margin-top: 120px; */
  padding-bottom: 96px;
  margin-bottom: 24px;
}

.TestCardContent{
  display: flex;
  flex-direction: column;
}
.TestCardHeader{
  display: flex;
  flex-direction: row;
  padding-top: 32px;
}
.PortraitContainer{
  margin-left: 40px;
  margin-right: 20px;
}
.CharacterTextContainer{
  text-align: left;
}
.CharacterName{
  margin-bottom: 0px!important;
  color: #734BF9;
}
.TestimonyTextContainer{
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 32px;
}
.TellUsSection{
  text-align: left;
  margin-bottom: 8px;
}
#TellusArea{
  margin-top: 8px;
}

#NavBarRow{
  display: flex;
  flex-direction: row;
}
.right{
  align-self: flex-start;
}
.left{
  align-self: flex-end;
}

#NavBarContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
#NavBarLogo{
  width: 113px;
  height: 40px;
  margin-left:60px;
  margin-top:47px;
}
.gray{
  background-color: antiquewhite;
}
.black{
  margin-right: 40px;
  margin-top: 40px;
} 

.nav-tabs {
  background-color: #F8F8F8;
  color: #200C63;
  border-radius: 8px; 
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #9678FA;
  color: white;
  border-bottom: none;
}

.nav-tabs .nav-link {
  border-radius: 8px;
}

.nav-tabs .nav-link, .nav-tabs .nav-link.active {
  border-bottom: 0px !important;
}

.tab-content .tab-pane {
  border: none;
}

.AboutTab{
  width: 1/3;
}

.SmHeroSection{
  width: 100%;
  background-image: url('./assets/heroBG.svg');
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;
  display: none;
}

/*  */
@media screen and (max-width: 1148px){
  .HeroText{
    font-size: 64px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 767px){
  #FormRow{
    flex-direction: column!important;
    padding-bottom: 78px;
  }
  .SmHeroSection{
    display: block;
  }
  .HeroSection{
    display: none;
  }
} 
@media screen and(min-width:768) {
  
  .HeroSection{
    display: none;
  }
} 
@media screen and (min-width: 1650px) {
  #NavBarLogo{
    margin-left: 300px;
  }
  #navbarButton{
    margin-right: 260px;
  }
}




#SmHeroParagraph{
  text-align: left;
  margin-bottom: 60px;
  font-weight: 900;
}

.Purple400Button{
  width: 100%;
  border-radius: 0px;
  background-color: #9678FA;
  border: none;
  color: white;
  padding-top: 16px;
  padding-bottom: 16px;
}
.nav-link{
  color: #200C63!important;
}
.nav-link.active{
  color: white!important;
}


.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #ffffff;
}
#navbarButton:hover{
  background-color: white;
  color:#200C63;
}
#heroButton:hover{
  background-color: #734BF9;
}
#SmHeroButton:hover{
  background-color: #734BF9;
}
#heroButton:focus{
  background-color: #B9A5FC;
}
#SmHeroButton:focus{
  background-color: #B9A5FC;
}
#heroButton:active{
  background-color: #734BF9;
  border: 1px, solid, #142566;
}
#SmHeroButton:active{
  background-color: #734BF9;
  border: 1px, solid, #142566;
}
#heroButton:disabled{
  color: #C6C6CD;
  background-color: #8C8D9B;
}
#SmHeroButton:disabled{
  color: #C6C6CD;
  background-color: #8C8D9B;
}


/* .row, .Row{
  padding-left: 0px!important;
  padding-right: 0px!important;
} */

#phoneP{
  margin-top: 32px;
  height: 26px;
}

@media screen and (max-width: 767px){
  #FormRow{
    width: auto;
    margin-left: 40px!important;
    margin-right: 40px!important;
  }
}

/* Colors */
.Gray50{color:#F8F8F8}
.Gray100{color:#E9EAEC}
.Gray200{color:#E2E2E6}
.Gray300{color:#C6C6CD}
.Gray400{color:#A9A9B4}
.Gray500{color:#8C8D9B}
.Gray600{color:#6F7082}
.Gray700{color:#525469}
.Gray800{color:#353750}
.Gray900{color:#272944}
.Purple50{color: #FFFFFF;}
.Purple100{color: #EEE9FE;}
.Purple200{color: #DCD2FD;}
.Purple300{color: #B9A5FC;}
.Purple400{color: #9678FA;}
.Purple500{color: #734BF9;}
.Purple600{color: #501EF7;}
.Purple700{color: #4018C6;}
.Purple800{color: #301294;}
.Purple900{color: #200C63;}
.White{color: white;}

#FormRow div {
  padding-right: 0px!important; 
  padding-left: 0px!important; 
}
/* #FormRow .row {
  margin: 0px!important;
} */